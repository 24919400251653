@import '../../../../scss/theme-bootstrap';

.sitewide-footer-formatter {
  position: relative;
  clear: both;
  margin: 0 auto;
  text-align: $ldirection;
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      flex-direction: row;
    }
  }
  &__social-network-block {
    flex: 1 1 10%;
    order: 2;
    padding: 15px 0;
    border-top: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
    width: 100vw;
    position: relative;
    #{$ldirection}: 50%;
    #{$rdirection}: 50%;
    margin: 0 -50vw;
    @include breakpoint($landscape-up) {
      border: none;
      padding: 25px 0;
      order: 1;
      #{$ldirection}: auto;
      #{$rdirection}: auto;
      margin: 0;
    }
  }
  &__signup-block {
    flex: 0 100%;
    order: 1;
    padding: 20px 0;
    @include breakpoint($landscape-up) {
      border-#{$ldirection}: 1px solid $color-light-grey;
      padding: 25px 30px;
      flex: 1 1 25%;
      order: 3;
    }
  }
  &__links-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    order: 3;
    width: 100%;
    padding: 15px 0;
    @include breakpoint($landscape-up) {
      padding: 25px 80px;
      flex: 1 1 65%;
      order: 2;
    }
  }
  &__bottom {
    padding-top: 38px;
    padding-bottom: 38px;
    text-align: center;
    @include breakpoint($landscape-up) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    p {
      margin: 0;
    }
    &-wrapper {
      display: block;
      @include breakpoint($landscape-up) {
        display: flex;
      }
    }
  }
  &__links {
    display: block;
    margin: 0;
    text-decoration: none;
    width: 100%;
    word-wrap: break-word;
    border-top: 1px solid $color-grey;
    padding: 12px 0;
    &:first-child {
      border: none;
    }
    @include breakpoint($landscape-up) {
      border: none;
      padding: 0;
      max-width: 100%;
      flex: 1 1 auto;
      width: auto;
    }
  }
  &__language-toggle {
    width: auto;
    display: block;
    @include breakpoint($landscape-up) {
      order: 3;
    }
  }
  &__copyright {
    width: 100%;
    display: inline-block;
    @include breakpoint($landscape-up) {
      order: 1;
      width: auto;
      flex: 1;
      margin: auto;
    }
  }
}

.elc-search-active-screen-wrapper {
  .elc-grid-container {
    .elc-product-brief {
      .elc-product-badge {
        font-family: $font--pretendard-semi-bold;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 0;
          top: 0;
          width: 50px;
          height: 50px;
          font-size: get-rem(11px);
          line-height: 1.18;
        }
      }
      .elc-size-picker-wrapper {
        .slick-slide {
          margin-#{$rdirection}: 3%;
          margin-#{$ldirection}: 3%;
        }
      }
    }
  }
}

.elc-search-enhanced-grid-wrapper {
  .elc-grid-container {
    .elc-slider-view-wrapper {
      .slick-list {
        .slick-slide {
          margin-#{$rdirection}: 3%;
          margin-#{$ldirection}: 3%;
          span {
            min-width: 52px;
            font-size: 10px;
          }
        }
      }
    }
  }
}

